import 'App.css'
import Home from 'home/Home'

const App = () => {
  return (
    <Home/>
  );
}

export default App
